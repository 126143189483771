<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					나의 리뷰
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="sub" class="sub mypage">
			<!-- content -->
			<section class="content">
				<div class="centered centered--large" v-if="reviews.length === 0">
                    <div class="centered__wrap">
                        <div class="textbox">
                            <div class="textbox__icon">
                                <img src="@/assets/images/img_warning.png" alt="작성하신 리뷰가 없습니다." style="width:64px">
                            </div>
                            <h3 class="textbox__title">
                                {{t('10377')}}
                            </h3>
                            <p class="textbox__description" v-html="t('10378')">
                            </p>
                            <div class="textbox__button">
                                <div class="row">
									<router-link to="/rooms">
										<button type="button" class="btn btn-primary purple">
											 {{t('10028')}}
										</button>
									</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="box" v-else>
					<!-- [D] 리뷰들 -->
					<div class="myreview">
						<!-- 1 -->
						<div class="review__item" v-for="review in reviews" :key="review.SEQ">
							<div class="review__top">
								<h3 class="review__subtitle">
									{{review.HOTEL_NM}}
								</h3>
								<div class="review__menu">
									<router-link :to="{path: '/mypage/myreview/modify', query: { ...review } }" v-if="review.editable">{{t('10329')}}</router-link>
									<a @click="deleteReview(review.SEQ)">{{t('10330')}}</a> 
								</div>
							</div>
							<div class="review__body">
								<div class="flex">
									<div class="review__type">
										{{review.PROD_NM}} - {{review.PROD_TYPE}}
									</div>
									<div class="review__name">
										{{userData.nick_nm}}
									</div>
								</div>
								<div class="flex">
									<div class="review__grad">
										<i v-for="i in 5" :key="i" class="icon" :class="i <= review.SCORE ? 'icon-star' : 'icon-star--light'"></i>
									</div>
									<div class="review__date">
										{{review.YMD}}
									</div>
								</div>
								<div class="review__slider">
									<div class="carousel">
										<div class="carousel__slider" data-slider="carousel">
											<swiper :slides-per-view="1.1" :space-between="16">
												<swiper-slide v-for="image in review.images" :key="image.SUB_SEQ">
													<router-link :to="'/layers/image?url=' + image.IMG_URL" custom v-slot="{ navigate }">
														<div class="carousel__image noneimage" @click="navigate">
															<div class="imagebg" :style="{'background-image': 'url(\'' + image.IMG_URL + '\')'}" />
														</div>
													</router-link>
												</swiper-slide>
											</swiper>
										</div>
									</div>
								</div>
								<div class="review__desc">
									{{review.REVIEW}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder" v-if="reviews.length > 0">
		<div class="pager">
			<a @click="onClickStartPage">
				<i class="icon icon-first"></i>
			</a>
			<a @click="onClickPrevPage">
				<i class="icon icon-left--light"></i>
			</a>
			<a
				v-for="index in Math.min(maxPage, 5)" 
				:key="index" 
				:class="{'active' : (page === pageOffset + index)}"
				@click="onClickMovePage(pageOffset + index)"
			>
				{{pageOffset + index}}
			</a>
			<a @click="onClickNextPage">
				<i class="icon icon-right--light"></i>
			</a>
			<a @click="onClickEndPage">
				<i class="icon icon-last"></i>
			</a>
		</div>
	</div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router'
import { useStore } from 'vuex';
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
dayjs.locale("ko");
export default {
	setup() {
		const router = useRouter();
		const store = useStore();

		const reviews = computed(() => store.state.mmy01.reviews.map(review => (
			{
				...review,
				YMD: dayjs(review.YMD).format("YYYY.MM.DD"),
				editable: dayjs(review.YMD).diff(dayjs().startOf('day'), "day") < -7 ? false : true
			}
		)));
		const { t }= useI18n() //번역필수 모듈
		const pageSize = ref(3);
		const totalItem = computed(()=> reviews.value.length > 0	? reviews.value[0].TOTAL_RECORDS : 0);
		const maxPage = computed(() => Math.ceil(totalItem.value / pageSize.value));
		const page = ref(1);
		const startPage = computed(() => (page.value - 1)/5 * 5 + 1);
		const endPage = computed(() => Math.min(startPage.value + 4, maxPage.value));
		const pageOffset = computed(() => Math.max(Math.min(page.value - 3, maxPage.value - 5), 0));
		const userData = computed(() => store.state.userData);

		onMounted(() => {
			store.dispatch("mmy01/fetchReviews", {
				mem_id: userData.value.mem_token,
				pagesize: pageSize.value,
				gotopage: 1
			});
		})

		const deleteReview = async (seq) => {
			const status = await store.dispatch("mmy01/deleteReview", { mem_id: userData.value.mem_token, seq });
			if(status === "0000") {
				store.dispatch("mmy01/fetchReviews", {
				mem_id: userData.value.mem_token,
					pagesize: pageSize.value,
					gotopage: page.value
				});
			}
		}

		const updatePage = (index) => {
			if(page.value !== index){
				store.dispatch("mmy01/fetchReviews", {
				    mem_id: userData.value.mem_token,
					pagesize: pageSize.value,
					gotopage: index
				});
				page.value = index;
			}
		}

		const onClickMovePage = (index) => {
			updatePage(index);
		}

		const onClickNextPage = () => {
			if(page.value < maxPage.value){
				updatePage(page.value + 1);
			}
		}
		const onClickPrevPage = () => {
			if(page.value > 1){
				updatePage(page.value - 1);
			}
		}	

		const onClickEndPage = () => {
			updatePage(maxPage.value);
		}
		const onClickStartPage = () => {
			updatePage(1);
		}

		return {
			reviews,
			userData: computed(() => store.state.userData),
			goBack: () => {
				router.back();
			},
			maxPage,
			page,
			onClickMovePage,
			onClickNextPage,
			onClickPrevPage,
			onClickEndPage,
			onClickStartPage,
			startPage,
			endPage,
			pageOffset,
			deleteReview,
			t,
			i18n
		}
	},
}
</script>